import { Environment } from '../types';
import { Config } from './types';
import { configProd } from './prod';

// ----------- THIS DATA IS HYDRATED ON THE FRONTEND!!!! ------------
export const configDev: Config = {
  ...configProd,
  environment: Environment.DEV,
  frontendBaseUrl: 'https://dev.swisstranscribe.ch',
  apiBaseUrl: 'https://services.dev.swisstranscribe.ch'
};
