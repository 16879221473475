exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-cockpit-tsx": () => import("./../../../src/pages/cockpit.tsx" /* webpackChunkName: "component---src-pages-cockpit-tsx" */),
  "component---src-pages-faq-tsx": () => import("./../../../src/pages/faq.tsx" /* webpackChunkName: "component---src-pages-faq-tsx" */),
  "component---src-pages-funktionen-tsx": () => import("./../../../src/pages/funktionen.tsx" /* webpackChunkName: "component---src-pages-funktionen-tsx" */),
  "component---src-pages-imprint-tsx": () => import("./../../../src/pages/imprint.tsx" /* webpackChunkName: "component---src-pages-imprint-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-preise-tsx": () => import("./../../../src/pages/preise.tsx" /* webpackChunkName: "component---src-pages-preise-tsx" */),
  "component---src-pages-ueber-uns-tsx": () => import("./../../../src/pages/ueber-uns.tsx" /* webpackChunkName: "component---src-pages-ueber-uns-tsx" */)
}

