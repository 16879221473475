const routes = {
  home: {
    path: '/'
  },
  pricing: {
    path: '/preise/'
  },
  functionality: {
    path: '/funktionen/'
  },
  faq: {
    path: '/faq/'
  },
  aboutUs: {
    path: '/ueber-uns/'
  },
  cockpit: {
    path: '/cockpit/'
  },
  imprint: {
    path: '/imprint/'
  }
};

export { routes };
