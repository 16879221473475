import React from 'react';

export default function EqIcon({ height = '18px' }: { height: string }) {
  return (
    <svg height={height} viewBox="0 0 109.375 47.129687666893005">
      <g transform="translate(-10.9375, -42.0601561665535) scale(1.3671875)" className="css-1lvk19o" fill="currentColor">
        <g xmlns="http://www.w3.org/2000/svg">
          <rect x="8" y="30.764" width="8.984" height="2.912"></rect>
          <rect x="8" y="37.075" width="8.984" height="2.913"></rect>
          <rect x="8" y="43.389" width="8.984" height="2.912"></rect>
          <rect x="8" y="49.699" width="8.984" height="2.914"></rect>
          <rect x="8" y="56.012" width="8.984" height="2.913"></rect>
          <rect x="8" y="62.323" width="8.984" height="2.913"></rect>
          <rect x="22.204" y="43.389" width="8.983" height="2.912"></rect>
          <rect x="22.204" y="49.699" width="8.983" height="2.914"></rect>
          <rect x="22.204" y="56.012" width="8.983" height="2.913"></rect>
          <rect x="22.204" y="62.323" width="8.983" height="2.913"></rect>
          <rect x="22.204" y="43.389" width="8.983" height="2.912"></rect>
          <rect x="22.204" y="49.699" width="8.983" height="2.914"></rect>
          <rect x="22.204" y="56.012" width="8.983" height="2.913"></rect>
          <rect x="22.204" y="62.323" width="8.983" height="2.913"></rect>
          <rect x="36.407" y="37.075" width="8.983" height="2.913"></rect>
          <rect x="36.407" y="43.389" width="8.983" height="2.912"></rect>
          <rect x="36.407" y="49.699" width="8.983" height="2.914"></rect>
          <rect x="36.407" y="56.012" width="8.983" height="2.913"></rect>
          <rect x="36.407" y="62.323" width="8.983" height="2.913"></rect>
          <rect x="36.407" y="37.075" width="8.983" height="2.913"></rect>
          <rect x="36.407" y="43.389" width="8.983" height="2.912"></rect>
          <rect x="36.407" y="49.699" width="8.983" height="2.914"></rect>
          <rect x="36.407" y="56.012" width="8.983" height="2.913"></rect>
          <rect x="36.407" y="62.323" width="8.983" height="2.913"></rect>
          <rect x="50.609" y="56.012" width="8.983" height="2.913"></rect>
          <rect x="50.609" y="62.323" width="8.983" height="2.913"></rect>
          <rect x="50.609" y="56.012" width="8.983" height="2.913"></rect>
          <rect x="50.609" y="62.323" width="8.983" height="2.913"></rect>
          <rect x="64.813" y="37.075" width="8.983" height="2.913"></rect>
          <rect x="64.813" y="43.389" width="8.983" height="2.912"></rect>
          <rect x="64.813" y="49.699" width="8.983" height="2.914"></rect>
          <rect x="64.813" y="56.012" width="8.983" height="2.913"></rect>
          <rect x="64.813" y="62.323" width="8.983" height="2.913"></rect>
          <rect x="64.813" y="37.075" width="8.983" height="2.913"></rect>
          <rect x="64.813" y="43.389" width="8.983" height="2.912"></rect>
          <rect x="64.813" y="49.699" width="8.983" height="2.914"></rect>
          <rect x="64.813" y="56.012" width="8.983" height="2.913"></rect>
          <rect x="64.813" y="62.323" width="8.983" height="2.913"></rect>
          <rect x="79.018" y="30.764" width="8.982" height="2.912"></rect>
          <rect x="79.018" y="37.075" width="8.982" height="2.913"></rect>
          <rect x="79.018" y="43.389" width="8.982" height="2.912"></rect>
          <rect x="79.018" y="49.699" width="8.982" height="2.914"></rect>
          <rect x="79.018" y="56.012" width="8.982" height="2.913"></rect>
          <rect x="79.018" y="62.323" width="8.982" height="2.913"></rect>
        </g>
      </g>
    </svg>
  );
}
