import { AppBar, Box, Button, ButtonGroup, Divider, Drawer, Toolbar, Typography } from '@mui/material';
import EqIcon from './icons/EqIcon';
import { Link } from 'gatsby';
import { navigate } from '@reach/router';
import { routes } from '../routes';
import { useState } from 'react';
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';
import { useLocation } from '@reach/router';

const TopBar = () => {
  const location = useLocation();
  const [open, setOpen] = useState(false);

  const toggleDrawer = (newOpen: boolean) => () => {
    setOpen(newOpen);
  };

  const DrawerList = (
    <Box sx={{ width: 300, display: 'flex', flexDirection: 'column' }} role="presentation" onClick={toggleDrawer(false)}>
      <Button sx={{ color: 'black', ml: 'auto' }}>
        <CloseIcon
          onClick={() => {
            toggleDrawer(false);
          }}
        />
      </Button>
      <Divider />
      <Button
        component={Link}
        to={routes.pricing.path}
        sx={{
          fontWeight: location.pathname === routes.pricing.path ? 'bold' : 'normal'
        }}
      >
        Preise
      </Button>
      <Divider />
      <Button
        component={Link}
        to={routes.functionality.path}
        sx={{
          fontWeight: location.pathname === routes.functionality.path ? 'bold' : 'normal'
        }}
      >
        Funktionen
      </Button>
      <Divider />
      <Button
        component={Link}
        to={routes.faq.path}
        sx={{
          fontWeight: location.pathname === routes.faq.path ? 'bold' : 'normal'
        }}
      >
        FAQ
      </Button>
      <Divider />
      <Button
        component={Link}
        to={routes.aboutUs.path}
        sx={{
          fontWeight: location.pathname === routes.aboutUs.path ? 'bold' : 'normal'
        }}
      >
        Über swiss transcribe
      </Button>
      <Divider />
      <Button
        component={Link}
        to={routes.cockpit.path}
        sx={{
          fontWeight: location.pathname === routes.cockpit.path ? 'bold' : 'normal'
        }}
      >
        Cockpit
      </Button>
      <Divider />
    </Box>
  );

  return (
    <AppBar position="static" sx={{ boxShadow: 'none', backgroundColor: 'transparent' }}>
      <Toolbar
        sx={{
          ml: {
            xs: 2,
            md: 0,
            lg: 2
          },
          mr: {
            xs: 2,
            md: 0,
            lg: 2
          },
          mt: 2,
          mb: 2,
          p: 0
        }}
      >
        <Link to={routes.home.path} style={{ display: 'contents', color: 'unset' }}>
          <EqIcon />
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
            <Typography
              color="white"
              fontFamily="HandelGothicRegular"
              sx={{
                fontSize: {
                  sx: '18px',
                  sm: '28px'
                }
              }}
              paddingLeft="16px"
            >
              swiss transcribe
            </Typography>
            <Box
              sx={{
                borderRadius: '2px',
                p: '0px 4px',
                bgcolor: 'green',
                display: 'flex',
                fontSize: {
                  xs: '9px'
                  // lg: '14px'
                },
                marginTop: '6px',
                fontWeight: 'bold'
              }}
            >
              BETA
            </Box>
          </Box>
        </Link>
        <ButtonGroup
          variant="contained"
          aria-label="Basic button group"
          sx={{
            display: {
              xs: 'none',
              md: 'block'
            },
            ml: 'auto'
          }}
        >
          <Button
            component={Link}
            to={routes.pricing.path}
            sx={{
              backgroundColor: location.pathname === routes.pricing.path ? '#A33A3B' : ''
            }}
          >
            Preise
          </Button>
          <Button
            component={Link}
            to={routes.functionality.path}
            sx={{
              backgroundColor: location.pathname === routes.functionality.path ? '#A33A3B' : ''
            }}
          >
            Funktionen
          </Button>
          <Button
            component={Link}
            to={routes.faq.path}
            sx={{
              backgroundColor: location.pathname === routes.faq.path ? '#A33A3B' : ''
            }}
          >
            FAQ
          </Button>
          <Button
            component={Link}
            to={routes.aboutUs.path}
            sx={{
              backgroundColor: location.pathname === routes.aboutUs.path ? '#A33A3B' : ''
            }}
          >
            Über swiss transcribe
          </Button>
          <Button
            component={Link}
            to={routes.cockpit.path}
            sx={{
              backgroundColor: location.pathname === routes.cockpit.path ? '#A33A3B' : ''
            }}
          >
            Cockpit
          </Button>
        </ButtonGroup>
        <Button
          sx={{
            ml: 'auto',
            display: {
              xs: 'flex',
              md: 'none'
            },
            alignItems: 'center',
            justifyContent: 'end',
            color: 'white',
            padding: '0'
          }}
          onClick={toggleDrawer(true)}
        >
          <MenuIcon />
        </Button>
        <Drawer anchor="right" open={open} onClose={toggleDrawer(false)}>
          {DrawerList}
        </Drawer>
        {/* <Typography variant="h6" noWrap component="div" sx={{ flexGrow: 1, display: { xs: 'none', sm: 'block' } }}></Typography> */}
        {/* <IconButton size="large" edge="start" color="inherit" aria-label="open drawer" sx={{ mr: 2 }}>
              <MenuIcon />
            </IconButton> */}
      </Toolbar>
    </AppBar>
  );
};

export default TopBar;
