export enum CheckoutPackageTypes {
  Package_CHF_8 = '8',
  Package_CHF_12 = '12',
  Package_CHF_24 = '24',
  Package_CHF_48 = '48',
  Package_CHF_96 = '96'
}
export interface CheckoutPayload {
  email: string;
  package: CheckoutPackageTypes;
}
